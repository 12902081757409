//
// remote.js
//
// Created by Michail Resvanis on 10/08/2017.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential.
//
// Copyright (C) 2017 Futurae Technologies AG - All rights reserved.
// For any inquiry, contact: legal@futurae.com
//


define('remote',['jquery', 'debug'], function ($, Debug) {
  var log = Debug("soundproof:remote");

  function Remote(config) {
    var self = this;

    self.config = config;
    self.sessionToken = null;

    self.timeSync = function () {
      return self.sendUserRequest("time");
    };

    self.sendAudioData = function (audioSampleId, start, end, data, key, iv,
                                   contentType) {
      return self.sendUserRequest("audio/wav?sample_id=" + audioSampleId +
                                  "&start=" + start + "&end=" + end, null, {
        contentType: contentType,
        data: data,
        processData: false,
        type: "PUT",
        headers: {
          "SP-KEY": key + "," + iv
        }
      });
    };

    self.sendSockConnError = function (sockjsEvent) {

      log("Attempting to report websocket/ajax connection error via jsonp");
      log("error contents", sockjsEvent);

      $.ajax({
        url: self.config.baseURL + "/" + "sockconnerror?" +
             "session_token=" + self.sessionToken +
             "&http_status_code=" + (sockjsEvent.code ? sockjsEvent.code : "0") +
             "&error_string=" + sockjsEvent.type + "_" + sockjsEvent.reason,
        dataType: 'jsonp',
        success: function (data) {
          // This is not used at the moment, all we care is notify the server
          // will not be executed unless server correctly formats jsonp response with jquery callback
          log("jsonp request was successful");
        },
        error: function (jqXHR, textStatus, errorThrown) {
          if (textStatus === "parsererror") {
            // call in the usual case where the server just returns an empty (invalid) response
            log("jsonp request was successful")
          } else {
            log("jsonp request failed");
            log(textStatus);
            log(errorThrown);
          }
        }
      });

    };

    self.setSessionToken = function (sessionToken) {
      self.sessionToken = sessionToken;
    };

    self.sendUserRequest = function (endpoint, data, options) {
      return self.sendRequest(
        self.config.remote.apiPrefix + "/" + endpoint,
        data,
        options
      );
    };

    self.sendRequest = function (endpoint, data, options) {
      var result = $.Deferred();

      options = $.extend(true, {
        url: self.config.baseURL + endpoint,
        contentType: "application/json",
        data: JSON.stringify(data),
        headers: self.sessionToken ? {
          "Authorization": self.sessionToken
        } : {},
        type: data ? "POST" : "GET",
        dataType: "json"
      }, options);

      $.ajax(options)
        .fail(
          function (xhr, status, error) {
            log(xhr);
            result.reject(
              (xhr.responseJSON && xhr.responseJSON.error) ? xhr.responseJSON.error : status);
          }
        )
        .done(function (data) {
          if (data.error) {
            result.reject(data.error);
          } else {
            result.resolve(data);
          }
        });

      return result.promise();
    };
  }

  log("Ready");

  return Remote;
});

