//
// audio.js
//
// Created by Michail Resvanis on 10/08/2017.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential.
//
// Copyright (C) 2017 Futurae Technologies AG - All rights reserved.
// For any inquiry, contact: legal@futurae.com
//

define('audio',['adapter', 'recorder', 'jquery', './ultrasound-modulation', 'debug', 'rollbar'],
  function (Adapter, Recorder, $, Ultrasound, Debug, Rollbar) {
    var log = Debug("soundproof:audio");
    var context = null;
    var useDefaultDevice = "default";

    function Audio(config) {
      var self = this;

      var requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame;
      var getUserMedia = false;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

        getUserMedia = navigator.mediaDevices.getUserMedia;
      }

      var AudioContext = window.AudioContext || // Default
                         window.webkitAudioContext || // Safari and old versions of Chrome
                         false;

      log('Using WebRTC adapter. Browser details', Adapter.browserDetails);

      self.config = config;

      self.audioConstraints = {
        deviceId: undefined,
        sampleRate: {ideal: 44100},
        googAutoGainControl: true,
        googAutoGainControl2: true,
        mozAutoGainControl: true,
        mozNoiseSuppression: false,
        autoGainControl: true,
        noiseSuppression: false,
        echoCancellation: false
      };

      if (context) {
        context.close().then(function () {
          log('Old context closed');
        }).catch(function (e) {
          log(e);
        });
      }
      context = new AudioContext();
      self.context = context;

      self.ultrasound = new Ultrasound(config);
      self.ultrasoundPayload = null;
      self.ultrasoundParams = null;
      self.initAttempt = null;
      self.mediaStream = null;
      self.recorder = null;
      self.recordingStart = null;
      self.recordingEnd = null;
      self.recorderWorkerError = null;
      self.stream = null;
      self.deferred = null;

      self.analyserNode = null;
      self.analyserUpdate = 0;
      self.analyserUpdateMax = 0;
      self.analyserMagnitudeCap = 150;
      self.analyserResult = null;

      // Return the target sample rate of the encoded audio file
      self.targetSampleRate = function () {
        // self.context.sampleRate is the sample rate of the AudioContext, which depends
        // on the current status of the underlying audio input hardware (ie recording sample rate)
        if (self.context.sampleRate < self.config.audio.targetSampleRate && self.config.audio.allowUpSampling) {
          return self.config.audio.targetSampleRate;
        }
        if (self.context.sampleRate > self.config.audio.targetSampleRate && self.config.audio.allowDownSampling) {
          return self.config.audio.targetSampleRate;
        }
        return self.context.sampleRate;
      };

      self.recordingSampleRate = function () {
        // self.context.sampleRate is the sample rate of the AudioContext, which depends
        // on the current status of the underlying audio input hardware (ie recording sample rate)
        return self.context.sampleRate;
      };

      self.init = function () {

        self.initAttempt = $.Deferred();

        self.setupUserMedia();

        return self.initAttempt.promise();
      };

      self.setupUserMedia = function (selectedDeviceId) {
        if (!getUserMedia) {
          log("Browser does not provide getUserMedia function");
          self.initAttempt.reject(undefined, "Unsupported browser - no getUserMedia function provided");
          return;
        }

        self.stopStreamTracks();

        // TODO Safari doesn't yet support device selection, skip for now
        if (Adapter.browserDetails.browser === "safari") {
          selectedDeviceId = useDefaultDevice;
        }

        log("Selected deviceId:", selectedDeviceId);
        if (selectedDeviceId == null) {
          log("Attempting to get access to audio input");
          getUserMedia.call(navigator.mediaDevices, {audio: self.audioConstraints})
                      .then(self.gotFirstStream)
                      .then(self.gotDevices)
                      .catch(self.initAttempt.reject);
        } else {
          self.audioConstraints.deviceId = (selectedDeviceId === useDefaultDevice) ? undefined : {exact: selectedDeviceId};
          log("Attempting to get access to selected audio input");
          getUserMedia.call(navigator.mediaDevices, {audio: self.audioConstraints})
                      .then(self.initSuccessWithCtxResume)
                      .catch(self.initAttempt.reject);
        }


      };

      self.gotFirstStream = function (stream) {
        self.stream = stream;

        // enumerate device after successful getUserMedia call, because depending on mic permission device labels
        // only become available at this point
        return navigator.mediaDevices.enumerateDevices();

      };

      self.gotDevices = function (deviceInfos) {

        log("Got device infos");
        // self.config.audio.deviceId = "default";
        log("User supplied deviceId", self.config.audio.deviceId);
        var selectedDeviceId = self.config.audio.deviceId ? self.config.audio.deviceId : self.selectDevice(deviceInfos);

        self.setupUserMedia(selectedDeviceId);
      };

      self.selectDevice = function (deviceInfos) {

        var selectedDeviceId = useDefaultDevice;
        var foundBuiltin = false;

        // Apply heuristics to determine and select builtin microphone
        // Otherwise stick to the default

        for (var i = 0; i !== deviceInfos.length; ++i) {
          var deviceInfo = deviceInfos[i];
          log("device:", deviceInfo);

          if (deviceInfo.kind !== 'audioinput' || foundBuiltin) {
            continue;
          }

          var label = deviceInfo.label.toLowerCase();

          if (label.indexOf("output") === -1 &&
              label.indexOf("mic") !== -1 &&
              (label.indexOf("built") !== -1 ||
               label.indexOf("internal") !== -1)
          ) {
            log("Found built-in microphone deviceId:", deviceInfo.deviceId);
            selectedDeviceId = deviceInfo.deviceId;
            foundBuiltin = true;
          }

        }

        return selectedDeviceId;
      };


      self.initSuccessWithCtxResume = function (stream) {

        // try to resume audio context in case it was created prematurely
        // (before any user interaction on the page)
        if (self.context && self.context.resume) {
          self.context.resume().then(function () {
            log("Recording AudioContext resumed successfully");
            self.initSuccess(stream);
          }).catch(function (reason) {
            log("Failed to resume recording AudioContext", reason);
            self.initAttempt.reject(undefined, "SoundProof recording started prematurely. It should start after user has interacted with the page");
            Rollbar.error("Failed to resume recording AudioContext");
          });
        } else {
          // AudioContext.resume not found, just proceed
          log("No option to resume AudioContext");
          self.initSuccess(stream);
        }

      };

      self.initSuccess = function (stream) {
        self.stream = stream;

        log("Recording sample rate:", self.recordingSampleRate());
        log("Target sample rate:", self.targetSampleRate());

        var gainFilter = self.context.createGain();
        var inputPoint;

        // Create an AudioNode from the stream.
        var audioInput = self.context.createMediaStreamSource(self.stream);
        audioInput.connect(gainFilter);

        if (false /*self.gainSupported*/) {
          var destination = self.context.createMediaStreamDestination();
          var outputStream = destination.stream;
          gainFilter.connect(destination);
          var filteredTrack = outputStream.getAudioTracks()[0];
          stream.addTrack(filteredTrack);
          var originalTrack = stream.getAudioTracks()[0];
          stream.removeTrack(originalTrack);
          inputPoint = destination;

        } else {
          inputPoint = audioInput;
        }

        if (self.config.audio.analyser) {
          log("Creating analyser node");
          self.analyserNode = self.context.createAnalyser();
          self.analyserNode.fftSize = 2048;
          inputPoint.connect(self.analyserNode);
        }

        self.recorder = new Recorder(inputPoint, {
          workerDir: self.config.audio.encodingWorkerPath,
          numChannels: self.config.audio.format === "mp3" ? 2 : 1,
          targetSampleRate: self.targetSampleRate(),
          encoding: self.config.audio.format,
          options: {
            timeLimit: self.config.audio.duration / 1000, // seconds to record for
            encodeAfterRecord: false,
            ogg: {
              quality: 0.5
            },
            mp3: {
              bitRate: 96
            }
          },
          onTimeout: self.stopRecording,
          onComplete: self.saveAudio,
          onError: self.recordingError,
          onWorkerError: function (recorder, message) {
            self.recorderWorkerError = message;
            log(message);
          },
          onEncoderLoading: function (recorder, encoding) {
            log("Loading " + encoding + " encoder");
          },
          onEncoderLoaded: function (recorder, encoding) {
            log("Loaded " + encoding + " encoder");
          }
        });

        zeroGain = self.context.createGain();
        zeroGain.gain.value = 0.0;
        inputPoint.connect(zeroGain);
        zeroGain.connect(self.context.destination);

        self.initAttempt.resolve();
      };

      self.micVolumeIsSupported = function () {
        var MediaStream = window.webkitMediaStream || window.MediaStream;
        if (MediaStream) {
          return !!MediaStream.prototype.addTrack && !!MediaStream.prototype.removeTrack;
        }
        return false;
      };

      self.gainSupported = self.micVolumeIsSupported();

      self.startRecording = function () {

        if (self.recorderWorkerError) {
          return $.Deferred().reject(undefined, self.recorderWorkerError).promise();
        }

        log("Starting recorder");
        self.recorder.startRecording();
        self.recordingStart = new Date();
        self.deferred = $.Deferred();

        setTimeout(self.checkPowerLevel,
          self.config.audio.powerLevelCheckDelay);

        // In case of a problem with the recorder, add an additional timer
        // to stop recording and proceed within a reasonable time
        setTimeout(self.stopRecording,
          self.config.audio.duration + 500);

        if (self.ultrasoundPayload !== null) {
          // Start ultrasound emission after a while
          // (improves chances for iOS app to detect the first pattern emission)
          setTimeout(self.startUltrasound, 100);
        }

        return self.deferred;
      };

      self.stopRecording = function (cancel) {
        log("Stopping recorder");
        // The following sometimes results in the error: TypeError: undefined
        // is not an object (evaluating 'recBuffers.push') if this function
        // is called prematurely (before the time limit)
        if (self.recorder && cancel === true) {
          self.recorder.cancelRecording();
        } else if (self.recorder) {
          self.recorder.finishRecording();
        }
        if (self.recordingEnd === null) {
          self.recordingEnd = new Date();
        }
        if (self.ultrasound) {
          // Stop ultrasound emission after a while
          setTimeout(self.ultrasound.stop, 200);
        }
        if (self.deferred) {
          self.deferred.notify("end", self.recordingStart, self.recordingEnd);
        }

        self.stopStreamTracks();

        if (self.context) {
          self.context.close().catch(function (e) {
            log(e);
          });
        }
      };

      self.forceStopRecording = function () {
        log("Force stopping (cancelling) recorder");
        self.stopRecording(true);
      };

      self.forceStopUltrasound = function () {
        if (self.ultrasound) {
          self.ultrasound.stop();
        }
      };

      self.saveAudio = function (recorder, blob) {
        log("Reading audio file blob");
        reader = new window.FileReader();
        reader.readAsArrayBuffer(blob);
        reader.onload = function () {
          self.deferred.resolve(new Uint8Array(reader.result));
        };
        self.stopStreamTracks();
      };

      self.setEncoding = function (encoding) {
        if (self.recorder) {
          self.recorder.setEncoding(encoding);
        }
      };

      self.recordingError = function (recorder, message) {
        log(message);
      };

      self.checkPowerLevel = function () {

        if (self.recorder && !self.recorder.isRecording()) { // do not send power-level related notifications if recording is already done
          return;
        }

        var power = self.getPowerLevel(self.recorder.buffer[0]);
        if (power == null) {
          return;
        }

        if (power > self.config.audio.powerThreshold) {
          log("There is enough energy in the room");

        } else if (power === Number.NEGATIVE_INFINITY) {
          self.deferred.notify("zero_power");

        } else {
          self.deferred.notify("power");
        }
      };

      self.getPowerLevel = function (buffer) {

        if (buffer == null) {
          return null;
        }

        var sampleSize = buffer.length;
        var sumOfSquares = 0;

        for (var i = 0; i < sampleSize; i++) {
          var s = Math.max(-1, Math.min(1, buffer[i]));
          var normalizedSample = s < 0 ? s * 0x8000 : s * 0x7FFF;
          sumOfSquares += normalizedSample * normalizedSample;
        }

        var power = 10 * log10(sumOfSquares / sampleSize);
        log("Recording avg. power: " + power);

        return power;
      };

      self.stopStreamTracks = function () {
        if (self.stream) {
          self.stream.getTracks().forEach(function (track) {

            // TODO impossible mega hack to prevent safari 11 from permanently messing up
            // with the system audio sample rate (until safari process is closed)
            // when recording via bluetooth device (which btw always fails in safari 11 currently)
            if (Adapter.browserDetails.browser === "safari") {
              log("safari track hack stop");
              track.applyConstraints({sampleRate: 0})
              // track.stop();
            } else {
              track.stop();
            }
          });
          self.stream = null;
        }
      };

      self.startAnalyser = function () {
        if (!self.analyserResult) {
          log("Starting audio analyzer");
          self.analyserResult = $.Deferred();
          requestAnimationFrame.call(window, self.updateAnalyser);
        }

        return self.analyserResult.promise();
      };

      self.stopAnalyser = function () {
        if (self.analyserResult) {
          self.analyserResult.resolve();
          self.analyserResult = null;
        }
      };

      self.updateAnalyser = function () {
        if (!self.analyserResult) {
          self.analyserUpdate = 0;
          return;
        }

        var freqByteData = new Uint8Array(self.analyserNode.frequencyBinCount);

        self.analyserNode.getByteFrequencyData(freqByteData);

        var magnitude = 0;
        for (var i = 0; i < self.analyserNode.frequencyBinCount; i++) {
          magnitude += freqByteData[i];
        }
        magnitude = Math.min(self.analyserMagnitudeCap,
          magnitude / self.analyserNode.frequencyBinCount);
        self.analyserUpdate++;
        if (self.analyserUpdate > self.analyserUpdateMax) {
          //boost it a little bit by a constant, to make it a bit more responsive
          var current = Math.min(1,
            1.3 * magnitude / self.analyserMagnitudeCap);
          self.analyserResult.notify(Math.max(0.1, current));
          self.analyserUpdate = 0;
        }

        requestAnimationFrame.call(window, self.updateAnalyser);
      };

      self.startUltrasound = function () {
        self.ultrasound.send(self.ultrasoundPayload, self.ultrasoundParams);
      };

      self.enableUltrasound = function (payload, params) {
        self.ultrasoundPayload = payload;
        self.ultrasoundParams = params;
      };

      self.disableUltrasound = function () {
        self.ultrasoundFrequencies = null;
      };
    }

    function log10(val) {
      return Math.log(val) / Math.LN10;
    }

    log("Ready");

    return Audio;
  })
;

