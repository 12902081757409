define('recorder',['debug'], function (Debug) {
  var log = Debug("soundproof:webaudiorecorder");
  // internal: same as jQuery.extend(true, args...)
  var extend = function () {
    var target = arguments[0],
      sources = [].slice.call(arguments, 1);
    for (var i = 0; i < sources.length; ++i) {
      var src = sources[i];
      for (var key in src) {
        var val = src[key];
        target[key] = typeof val === "object" ? extend(typeof target[key] === "object" ? target[key] : {}, val) : val;
      }
    }
    return target;
  };

  var WORKER_FILE = {
    wav: "WebAudioRecorderWav.min.js",
    ogg: "WebAudioRecorderOgg.min.js",
    mp3: "WebAudioRecorderMp3.min.js"
  };

  // default configs
  var CONFIGS = {
    workerDir: "/",     // worker scripts dir (end with /)
    numChannels: 2,     // number of channels
    encoding: "wav",    // encoding (can be changed at runtime)
    targetSampleRate: 44100, // sample rate of encoded audio file

    // runtime options
    options: {
      timeLimit: 300,           // recording time limit (sec)
      encodeAfterRecord: false, // process encoding after recording
      progressInterval: 1000,   // encoding progress report interval (millisec)
      bufferSize: undefined,    // buffer size (use browser default)

      // encoding-specific options
      wav: {
        mimeType: "audio/wav"
      },
      ogg: {
        mimeType: "audio/ogg",
        quality: 0.5            // (VBR only): quality = [-0.1 .. 1]
      },
      mp3: {
        mimeType: "audio/mpeg",
        bitRate: 160            // (CBR only): bit rate = [64 .. 320]
      }
    }
  };

  // constructor
  var WebAudioRecorder = function (sourceNode, configs) {
    extend(this, CONFIGS, configs || {});
    this.context = sourceNode.context;
    if (this.context.createScriptProcessor == null)
      this.context.createScriptProcessor = this.context.createJavaScriptNode;
    this.input = this.context.createGain();
    sourceNode.connect(this.input);
    this.buffer = [];
    this.initWorker();
  };

  // instance methods
  extend(WebAudioRecorder.prototype, {
    isRecording: function () {
      return this.processor != null;
    },

    setEncoding: function (encoding) {
      if (this.isRecording())
        this.error("setEncoding: cannot set encoding during recording");
      else if (this.encoding !== encoding) {
        this.encoding = encoding;
        this.initWorker();
      }
    },

    setOptions: function (options) {
      if (this.isRecording())
        this.error("setOptions: cannot set options during recording");
      else {
        extend(this.options, options);
        this.worker.postMessage({command: "options", options: this.options});
      }
    },

    startRecording: function () {
      if (this.isRecording()) {
        this.error("startRecording: previous recording is running");
      } else {
        var numChannels = this.numChannels,
          targetSampleRate = this.targetSampleRate,
          buffer = this.buffer,
          worker = this.worker,
          that = this;

        var postBufferToWorker = function (inputBuffer) {
          for (var ch = 0; ch < numChannels; ++ch)
            buffer[ch] = inputBuffer.getChannelData(ch);
          worker.postMessage({command: "record", buffer: buffer});
        };

        this.processor = this.context.createScriptProcessor(
          this.options.bufferSize,
          this.numChannels, this.numChannels);
        this.input.connect(this.processor);
        this.processor.connect(this.context.destination);
        this.processor.onaudioprocess = function (event) {
          // log("got audio buffer", event.inputBuffer.sampleRate);
          var inputBuffer = event.inputBuffer;
          if (inputBuffer.sampleRate !== targetSampleRate) {

            // Making a copy of the buffer fixes:
            // "Invalid AudioBuffer. You may not use an AudioBuffer from an 'audioprocess' event."
            // on Edge, when passing the inputBuffer to the resampler
            var copyBuffer = that.context.createBuffer(
              numChannels, inputBuffer.length, inputBuffer.sampleRate);
            for (var ch = 0; ch < numChannels; ++ch) {
              if (copyBuffer.copyToChannel) {
                copyBuffer.copyToChannel(inputBuffer.getChannelData(ch), ch, 0);
              } else {// safari doesn't support copyToChannel
                copyBuffer.getChannelData(ch).set(inputBuffer.getChannelData(ch), 0);
              }
            }

            resampler(copyBuffer, targetSampleRate, function (result) {
              if (result instanceof Error) {
                that.onError(that, "Could not resample audio buffer: " + result.message);
                return;
              }
              postBufferToWorker(result.getAudioBuffer());
            });
          } else {
            postBufferToWorker(event.inputBuffer);
          }
        };
        this.worker.postMessage({
          command: "start",
          bufferSize: this.processor.bufferSize
        });
        this.startTime = Date.now();
      }
    },

    recordingTime: function () {
      return this.isRecording() ? (Date.now() - this.startTime) * 0.001 : null;
    },

    cancelRecording: function () {
      if (this.isRecording()) {
        this.input.disconnect();
        this.processor.disconnect();
        delete this.processor;
        this.worker.postMessage({command: "cancel"});
      } else
        this.error("cancelRecording: no recording is running");
    },

    finishRecording: function () {
      if (this.isRecording()) {
        this.input.disconnect();
        this.processor.disconnect();
        delete this.processor;
        this.worker.postMessage({command: "finish"});
      } else
        this.error("finishRecording: no recording is running");
    },

    cancelEncoding: function () {
      if (this.options.encodeAfterRecord) {
        if (this.isRecording()) {
          this.error("cancelEncoding: recording is not finished");
        } else {
          this.onEncodingCanceled(this);
          this.initWorker();
        }
      } else {
        this.error("cancelEncoding: invalid method call");
      }
    },

    initWorker: function () {
      if (typeof(this.worker) !== "undefined") {
        this.worker.terminate();
      }

      this.onEncoderLoading(this, this.encoding);
      this.worker = new Worker(this.workerDir + WORKER_FILE[this.encoding]);

      var that = this;
      this.worker.addEventListener("error", function () {
        that.onWorkerError(that, "initWorker: Could not initialize worker from URL " +
                                 that.workerDir + WORKER_FILE[that.encoding]);
      }, false);

      this.worker.onmessage = function (event) {
        var data = event.data;
        switch (data.command) {
          case "loaded":
            that.onEncoderLoaded(that, that.encoding);
            break;
          case "timeout":
            that.onTimeout(that);
            break;
          case "progress":
            that.onEncodingProgress(that, data.progress);
            break;
          case "complete":
            that.onComplete(that, data.blob);
            break;
          case "error":
            that.error(data.message);
        }
      };

      ///////////////////////////////////////////////////
      // timeLimit adjustment
      //
      // timeLimit is only used internally by the worker to calculate the maximum number of audio buffers
      // to be processed assuming a given duration and sample rate. This serves as the recording timer:
      // once the worker has processed the maximum number of buffers, it signals the end of the recording.
      //
      // The following adjustment ensures that the worker will correctly calculate the maximum number of
      // buffers, based on the recording sample rate (and not the target sample rate which is passed as
      // argument to the worker), without the need of changing the worker code.
      this.options.timeLimit = this.options.timeLimit * this.context.sampleRate / this.targetSampleRate;
      ////////////////////////////////////////////////////

      this.worker.postMessage({
        command: "init",
        config: {
          sampleRate: this.targetSampleRate, // sample rate of encoded audio file
          numChannels: this.numChannels,
          downSampleFactor: 1 // always 1 now (ie unused), keep param for backward compatibility with old workers
        },
        options: this.options
      });
    },

    error: function (message) {
      this.onError(this, "WebAudioRecorder.js:" + message);
    },

    // event handlers
    onEncoderLoading: function (recorder, encoding) {
    },
    onEncoderLoaded: function (recorder, encoding) {
    },
    onTimeout: function (recorder) {
      recorder.finishRecording();
    },
    onEncodingProgress: function (recorder, progress) {
    },
    onEncodingCanceled: function (recorder) {
    },
    onComplete: function (recorder, blob) {
      recorder.onError(recorder, "WebAudioRecorder.js: You must override .onComplete event");
    },
    onError: function (recorder, message) {
      console.log(message);
    },
    onWorkerError: function (recorder, message) {
    }
  });


  ///////////////////////////////////////////////////////////////////////////////////
  // Resampler
  // Adapted from: https://github.com/notthetup/resampler
  // ///////////////////////////////////////////////////////////////////////////////
  var OfflineAudioContext = window.OfflineAudioContext || // Default
                            window.webkitOfflineAudioContext || // Safari and old versions of Chrome
                            false;

  var promiseBased = !!(new OfflineAudioContext(1, 44100, 44100).startRendering());

  // log("promiseBased OfflineAudioContext.startRendering():", promiseBased);

  function resampler(input, targetSampleRate, oncomplete) {

    if (!input && !targetSampleRate) {
      return returnError('Error: First argument should be either a File, URL or AudioBuffer');
    }

    var inputType = Object.prototype.toString.call(input);
    if (inputType !== '[object String]' &&
        inputType !== '[object File]' &&
        inputType !== '[object AudioBuffer]' &&
        inputType !== '[object Object]') {
      return returnError('Error: First argument should be either a File, URL or AudioBuffer');
    }

    if (typeof targetSampleRate !== 'number' ||
        targetSampleRate > 192000 || targetSampleRate < 3000) {
      return returnError('Error: Second argument should be a numeric sample rate between 3000 and 192000');
    }

    if (inputType === '[object AudioBuffer]') {
      resampleAudioBuffer(input);
    } else {
      return returnError('Error: Unknown input type');
    }

    function returnError(errMsg) {
      console.error(errMsg);
      if (typeof oncomplete === 'function') {
        oncomplete(new Error(errMsg));
      }
      return;
    }

    function gotBuffer(resampledBuffer) {
      // log('Done Rendering');
      if (typeof oncomplete === 'function') {
        oncomplete({
          getAudioBuffer: function () {
            return resampledBuffer;
          }
        });
      }
    }

    function resampleAudioBuffer(audioBuffer) {

      var numCh_ = audioBuffer.numberOfChannels;
      var numFrames_ = audioBuffer.length * targetSampleRate / audioBuffer.sampleRate;

      var offlineContext_ = new OfflineAudioContext(numCh_, numFrames_, targetSampleRate);
      var bufferSource_ = offlineContext_.createBufferSource();
      bufferSource_.buffer = audioBuffer;

      if (!promiseBased) { // safari and old browsers
        offlineContext_.oncomplete = function (event) {
          gotBuffer(event.renderedBuffer);
        };
      }

      // log('Starting Offline Rendering');
      bufferSource_.connect(offlineContext_.destination);
      bufferSource_.start(0);
      var promise = offlineContext_.startRendering();

      if (promiseBased) { // modern browsers
        promise.then(function (resampledBuffer) {
          gotBuffer(resampledBuffer);
        }).catch(function (err) {
          // Note: The promise should reject when startRendering is called a second time on an OfflineAudioContext
          if (typeof oncomplete === 'function') {
            oncomplete(new Error(err));
          }
        });
      }
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////

  log("Ready");

  return WebAudioRecorder;

});

