//
// encryption.js
//
// Created by Michail Resvanis on 10/08/2017.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential.
//
// Copyright (C) 2017 Futurae Technologies AG - All rights reserved.
// For any inquiry, contact: legal@futurae.com
//


define('encryption',['jsencrypt', 'sjcl', 'debug'], function (JSEncrypt, sjcl, debug) {
  var log = debug("soundproof:encryption");

  function Encryption(config) {
    var self = this;

    self.config = config;

    self.rsacrypt = new JSEncrypt();
    self.rsacrypt.default_key_size = 2048;

    // CBC mode is implemented, however it has to be included with the --with-cbc
    // option and enabled at runtime by adding a disclaimer line of code:
    sjcl.beware["CBC mode is dangerous because it doesn't protect message integrity."]();

    self.setPublicKey = function (public_key) {
      self.rsacrypt.setPublicKey(public_key);
    };

    self.encrypt = function (data) {
      var bits = sjcl.codec.bytes.toBits(data);
      var key = sjcl.random.randomWords(4, 0);
      var iv = sjcl.random.randomWords(4, 0);
      var prp = new sjcl.cipher.aes(key);

      log("Encrypting audio data");

      encr = sjcl.mode.cbc.encrypt(prp, bits, iv, '');
      data = new Uint8Array(sjcl.codec.bytes.fromBits(encr));
      key = self.rsacrypt.encrypt(sjcl.codec.base64.fromBits(key));

      log("Encryption complete");

      return {
        data: data,
        key: key,
        iv: sjcl.codec.base64.fromBits(iv)
      };
    };
  }

  log("Ready");

  return Encryption;
});

