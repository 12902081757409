//
// storage.js
//
// Created by Michail Resvanis on 31/05/2018.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential.
//
// Copyright (C) 2017 Futurae Technologies AG - All rights reserved.
// For any inquiry, contact: legal@futurae.com
//


define('storage',['jquery', 'debug'], function ($, Debug) {
  var log = Debug("soundproof:storage");

  function Storage(type) {
    var self = this;


    self.type = type;
    self.storageObj = null;

    if (self.type === "localStorage" && storageAvailable(self.type)) {
      self.storageObj = localStorage;
    } else {
      throw "Unsupported storage type: " + self.type;
    }

    self.getItem = function (key) {
      return self.storageObj.getItem(key);
    };

    self.setItem = function (key, item) {
      self.storageObj.setItem(key, item);
      return true;
    };

  }

  storageAvailable = function (type) {
    var storage = null;
    try {
      var x = '__storage_test__';
      storage = window[type];
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    }
    catch (e) {
      return e instanceof DOMException && (
                 // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
             // acknowledge QuotaExceededError only if there's something already stored
             storage.length !== 0;
    }
  };

  log("Ready");
  return Storage;
});

