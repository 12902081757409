//
// socket.js
//
// Created by Michail Resvanis on 10/08/2017.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential.
//
// Copyright (C) 2017 Futurae Technologies AG - All rights reserved.
// For any inquiry, contact: legal@futurae.com
//


define('socket',['sockjs', 'jquery', 'debug', 'storage'], function (SockJS, $, Debug, Storage) {
  var log = Debug("soundproof:socket");

  var storedBaseURLKey = "__futurae_sock_baseURL";
  var probingTimeout = 1500;

  // Avoid xhr_streaming as there are some unsolved infinite loop issues
  // (e.g, https://github.com/sockjs/sockjs-client/issues/308,  https://github.com/sockjs/sockjs-client/issues/418)
  // (even if above issues are marked as closed, users are still noticing problems, and so did we)
  var defaultTransports = ['websocket', 'xhr-polling'];


  // due to browser limits, only one sockjs connection should ever be active
  var socket = null;
  var lastCloseEvent = {};

  var isProbingActive = true; // probing is allowed in the beginning
  var probingBaseURL = "";
  var autoSelectedTransports = null;


  function Socket(config) {
    var self = this;

    self.config = config;
    self.handlers = [];
    self.waitForMessage = {};

    self.storage = null;
    try {
      self.storage = new Storage("localStorage");
    } catch (e) {
      console.log(e); // this should never really happen in supported browsers
    }


    // Store actually used base URL, to be used by TransportProber in next run, instead of the default one
    if (self.storage) {
      self.storage.setItem(storedBaseURLKey, self.config.baseURL);
    }

    self.connectSocket = function (sessionToken) {
      // Session token is appended in the endpoint query params, to be used for
      // routing the websocket connection to the correct backend server by the
      // load balancer
      log("Connecting to socket");
      return self.connect(
        self.config.socket.endpoint + "?session_token=" + sessionToken,
        self.config.socket.transports);
    };

    self.onMessage = function (handler) {
      self.handlers.push(handler);
    };

    self.sendAuthentication = function (sessionToken, soundProofToken,
                                        targetSampleRate, recordingSampleRate, version, browserInfo, soundProofError) {

      if (soundProofError) {
        return self.send(JSON.stringify({
          'session_token': sessionToken,
          'soundproof_token': soundProofToken,
          'sample_rate': targetSampleRate,
          'rec_sample_rate': recordingSampleRate,
          'transport': socket.transport,
          'version': version,
          'user-agent': browserInfo,
          'soundproof_error': soundProofError
        }), "waitfornothingnew"); // just a random string, we don't wait for "rec" in this case.
      }

      return self.send(JSON.stringify({
        'session_token': sessionToken,
        'soundproof_token': soundProofToken,
        'sample_rate': targetSampleRate,
        'rec_sample_rate': recordingSampleRate,
        'transport': socket.transport,
        'version': version,
        'user-agent': browserInfo
      }), "rec");
    };

    self.sendTimeSyncRequest = function () {
      return self.send(JSON.stringify({
        'timesync': true
      }), "timesync");
    };

    self.sendError = function (errorMessage) {
      return self.send(JSON.stringify({
        'error': errorMessage
      }));
    };

    self.send = function (message, expectedMessageType) {
      log("Sending message:", message);

      if (socket.readyState !== SockJS.OPEN) {
        return $.Deferred().reject(lastCloseEvent).promise();
      }

      var waitForMessage = self.waitFor(expectedMessageType);

      socket.send(message);

      return waitForMessage;
    };

    self.waitFor = function (messageType) {
      var waitForMessage = $.Deferred();

      messageType = messageType || "__any__";
      (self.waitForMessage[messageType] || (self.waitForMessage[messageType] = [])).push(
        waitForMessage);

      return waitForMessage.promise();
    };

    self.connect = function (endpoint, transports) {
      isProbingActive = false; // signal probing stop

      if (self.config.baseURL !== probingBaseURL) {
        // ignore results
        log("Probing and actual base URLs differ. Discarding auto selected transports");
        autoSelectedTransports = null;
      } else {
        log("Auto selected transports", autoSelectedTransports);
      }


      if (socket) {
        log("Found existing socket connection. Closing");
        socket.close();
        socket = null;
      }

      var transportsToUse;
      if (transports && transports.length > 0) {
        transportsToUse = transports;
      } else if (autoSelectedTransports && autoSelectedTransports.length > 0) {
        transportsToUse = autoSelectedTransports;
      } else {
        transportsToUse = defaultTransports;
      }

      var options = {
        sessionId: 20, // each character has 32 (2^5) possibilities. entropy: 100 bits
        transports: transportsToUse
      };
      socket = new SockJS(self.endpointURI(endpoint), null, options);

      var connectionAttempt = $.Deferred();
      socket.onopen = connectionAttempt.resolve;
      socket.onmessage = self.callHandlers;
      socket.onclose = socket.onerror = function (e) {
        log("sockjs close/error", e);
        lastCloseEvent = e;

        // connection not open yet
        if (connectionAttempt.state() === "pending") {
          log("Rejecting connectionAttempt");
          connectionAttempt.reject(e);
          return;
        }

        // connection open but closed remotely or error occurred
        if (!$.isEmptyObject(self.waitForMessage)) {
          $.each(self.waitForMessage, function (key, deferredToBeRejected) {
            log("Rejecting waitForMessage: " + key);
            $.each(deferredToBeRejected, function (i, waitForMessage) {
              waitForMessage.reject(e);
            });
          });
          self.clearWaitForMessages();
        }
      };

      return connectionAttempt;
    };

    self.callHandlers = function (e) {
      if (e.type === "message") {
        log("Received message:", e.data);
        $.each(self.handlers, function (i, handler) {
          handler(e.data);
        });

        jsonResponse = JSON.parse(e.data);
        var messageType = jsonResponse.message_type;
        $.each([messageType, "__any__"], function (i, key) {
          if (self.waitForMessage[key]) {
            deferredToBeResolved = self.waitForMessage[key];
            delete self.waitForMessage[key];
            $.each(deferredToBeResolved, function (i, waitForMessage) {
              waitForMessage.resolve(jsonResponse);
            });
          }
        });
      } else {
        log("Received event:", e);
      }
    };

    self.clearWaitForMessages = function () {
      self.waitForMessage = {};
    };

    self.close = function () {
      if (socket) {
        socket.close();
        socket = null;
        self.clearWaitForMessages();
      }
    };

    self.endpointURI = function (endpoint) {
      return self.config.baseURL + endpoint;
    };
  }


  function TransportProber(config) {
    var self = this;

    self.config = config;

    self.transportsToProbe = defaultTransports;

    self.currentTransport = null;

    self.isSuccess = false;

    self.storage = null;
    try {
      self.storage = new Storage("localStorage");
    } catch (e) {
      console.log(e); // this should never really happen in supported browsers
    }

    self.nextTransport = function () {

      if (!isProbingActive || self.isSuccess) {
        return;
      }

      if (socket) {
        socket.onopen = socket.onerror = socket.onclose = $.noop;
        socket.close();
        socket = null;
      }

      log("Failed transport", self.currentTransport);

      // only one remaining, previous failed, so use the last one directly
      self.transportsToProbe.shift();
      if (self.transportsToProbe.length === 1) {
        autoSelectedTransports = self.transportsToProbe;
        log("All probed transports");
        log("Auto selecting last transport", self.transportsToProbe);
        return;
      }

      self.probeTransports();
    };

    self.success = function () {
      if (!isProbingActive) {
        return;
      }
      self.isSuccess = true;
      log("Auto selected transports", self.transportsToProbe);
      autoSelectedTransports = self.transportsToProbe;

      if (socket) {
        socket.close();
        socket = null;
      }
    };


    self.probeTransports = function () {

      if (socket || self.transportsToProbe.length < 2 || !isProbingActive) {
        return;
      }

      //Use previously actual used endpoint instead of default one, if possible
      if (self.storage) {
        probingBaseURL = self.storage.getItem(storedBaseURLKey);
      }

      if (probingBaseURL) {
        log("Using previously used base URL", probingBaseURL);
      } else {
        probingBaseURL = self.config.baseURL;
        log("Using default base URL", probingBaseURL);
      }

      var URL = probingBaseURL + self.config.socket.endpoint;
      self.currentTransport = self.transportsToProbe[0];

      var options = {
        sessionId: 20,
        transports: [self.currentTransport]
      };

      socket = new SockJS(URL, null, options);
      var timer = setTimeout(self.nextTransport, probingTimeout);

      socket.onopen = function () {
        socket.send(JSON.stringify({'ping': true}));
      };


      socket.onmessage = function (e) {
        if (e.type === "message" && JSON.parse(e.data)["pong"] === true) {
          clearTimeout(timer);
          self.success();
        }
      };

      socket.onclose = socket.onerror = function (e) {
        clearTimeout(timer);
        log("sockjs close/error", e);
        self.nextTransport();
      };

    };
  }

  Socket.TransportProber = TransportProber;

  log("Ready");

  return Socket;
});

