//
// timesync.js
//
// Created by Michail Resvanis on 10/08/2017.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential.
//
// Copyright (C) 2017 Futurae Technologies AG - All rights reserved.
// For any inquiry, contact: legal@futurae.com
//


define('timesync',['jquery', 'debug'], function ($, Debug) {
  var log = Debug("soundproof:timesync");

  function Time(config, remote, socket) {
    var self = this;

    self.config = config;
    self.remote = remote;
    self.socket = socket;

    self.thetas = null;
    self.lastSendTime = null;
    self.deferred = null;
    self.theta = null;

    self.run = function () {
      self.deferred = $.Deferred();
      self.thetas = [];
      self.sendTimeSyncRequest();
      return self.deferred;
    };

    self.syncDate = function (date) {
      if (self.theta === null) {
        log("Error: Clocks were not synchronized");
        self.theta = 0;
      }
      return date.getTime() + self.theta;
    };

    self.sendTimeSyncRequest = function () {
      if (self.config.timesync.websocket) { // Go through websocket (sockjs)
        self.socket.sendTimeSyncRequest()
          .done(self.receiveTheta)
          .fail(self.error);
      } else {// do normal AJAX request
        self.remote.timeSync()
          .done(self.receiveTheta)
          .fail(self.error);
      }
      self.lastSendTime = new Date().getTime();
    };

    self.receiveTheta = function (data) {
      var receiveTime = new Date().getTime();
      var theta = ((data.t1 - self.lastSendTime) + (data.t2 - receiveTime)) / 2;
      self.thetas.push(theta);

      if (self.thetas.length === self.config.timesync.count) {
        self.finishCalculation();
      } else {
        self.sendTimeSyncRequest();
      }
    };

    self.finishCalculation = function () {
      var avg = self.average(self.thetas);
      var std = self.standardDeviation(self.thetas, avg);

      var lowerBound = avg - std;
      var upperBound = avg + std;

      var thetasExcludingOutliers = [];

      for (var i = 0; i < self.thetas.length; i++) {
        var theta = self.thetas[i];
        if (theta >= lowerBound && theta <= upperBound) {
          thetasExcludingOutliers.push(theta);
        }
      }

      var latestTheta = self.average(thetasExcludingOutliers);

      log(
        "theta avg: " + avg + ", std: " + std + ", lower: " + lowerBound + ", upper: " + upperBound);
      log("Latest theta: " + latestTheta);

      self.theta = latestTheta;
      self.deferred.resolve(latestTheta);
    };

    self.average = function (data) {
      var sum = data.reduce(function (sum, value) {
        return sum + value;
      }, 0);

      return sum / data.length;
    };

    self.standardDeviation = function (values, avg) {
      if (avg === null) {
        avg = self.average(values);
      }

      var squareDiffs = values.map(function (value) {
        var diff = value - avg;
        var sqrDiff = diff * diff;
        return sqrDiff;
      });

      var avgSquareDiff = self.average(squareDiffs);
      var stdDev = Math.sqrt(avgSquareDiff);

      return stdDev;
    };

    self.error = function (e) {
      log("Failed with error:", e);
      self.deferred.reject(e);
    };
  }

  log("Ready");
  return Time;
});

