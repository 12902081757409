//
// soundproof.js
//
// Created by Michail Resvanis on 10/08/2017.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential.
//
// Copyright (C) 2017 Futurae Technologies AG - All rights reserved.
// For any inquiry, contact: legal@futurae.com
//


require.config({
  baseUrl: '/assets/javascript/soundproof',
  paths: {
    adapter: '../../external/adapter',
    debug: '../../external/debug',
    jquery: '../../external/jquery',
    jsencrypt: '../../external/jsencrypt',
    sjcl: '../../external/sjcl',
    sockjs: '../../external/sockjs',
    recorder: '../../external/recorder/WebAudioRecorder',
    rollbar: '../../external/rollbar.noconflict.umd' //NOTE: remove 'use strict' directives to prevent "TypeError: access to strict mode caller function is censored" in Firefox in combination with legacy ASP.NET WebForms projects. (https://mnaoumov.wordpress.com/2016/02/12/wtf-microsoftajax-js-vs-use-strict-vs-firefox-vs-ie/)
  },
  shim: {
    bootstrap: {
      deps: ['jquery']
    },
    recorder: {
      exports: 'WebAudioRecorder'
    }
  },
  // Add this map config in addition to any baseUrl or
  // paths config you may already have in the project.
  map: {
    // '*' means all modules will get 'jquery-private'
    // for their 'jquery' dependency.
    '*': {'jquery': 'jquery-private'},

    // 'jquery-private' wants the real jQuery module
    // though. If this line was not here, there would
    // be an unresolvable cyclic dependency.
    'jquery-private': {'jquery': 'jquery'}
  }
});


/**
 * The built-in JavaScript Error object.
 * @external Error
 * @see https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Error
 */

/**
 * The built-in web API DOMException interface.
 * @external DOMException
 * @see https://developer.mozilla.org/en-US/docs/Web/API/DOMException
 */

/**
 * SoundProof module
 * @namespace soundproof
 * @property {string} version - The version of the SoundProof JS library.
 */
// Doc produced with: jsdoc -c jsdoc_docstrap.conf -t ../../node_modules/ink-docstrap/template soundproof.js -R README.md -P ../../package.json -d ./doc/

define(
  '../../dist/soundproof',['adapter', 'remote', 'audio', 'encryption', 'socket', 'storage', 'timesync', 'jquery', 'debug', 'rollbar'],
  function (Adapter, Remote, Audio, Encryption, Socket, Storage, TimeSync, $, Debug, Rollbar) {
    var log = Debug("soundproof:main");

    /*
     Setting up debugging helper functions.

     Executing debug() in your browser's development console will globally
     enable logging. Use filters to limit logging to certain modules
     (example: debug("encryption")).

     To disable logging again use debug.disable(). Debugging settings are
     persisted through localStorage.
    */
    window.debug = window.d = function (filter) {
      Debug.enable(filter ? filter + ",soundproof:" + filter : "*");
    };

    window.debug.disable = window.debug.d = Debug.disable;

    var encodingWorkerPath = (function () {
      // Get the script URL, to be used for finding the path for loading the
      // encoding web workers
      var scriptSource = (function () {
        var scripts = document.getElementsByTagName('script'),
          script = scripts[scripts.length - 1];
        if (script.getAttribute.length !== undefined) {
          return script.src;
        }
        return script.getAttribute('src', -1);
      }());

      var parser = document.createElement('a');
      parser.href = scriptSource;
      return parser.pathname.substring(0, parser.pathname.lastIndexOf("/") + 1);
    }());

    log("Setting encoding workers path to: " + encodingWorkerPath);

    var defaultConfig = {
      baseURL: 'https://api.futurae.com',
      audio: {
        deviceId: undefined, // default system input
        powerThreshold: 42,
        enabledCompression: false,
        compressionBitrate: null,
        duration: 3000,
        allowUpSampling: true,
        allowDownSampling: true,
        targetSampleRate: 44100, // sample rate for encoded audio file, if up/down sampling is allowed
        powerLevelCheckDelay: 1000,
        format: "ogg", // Default. Can be changed by the server at will to wav
        // DO NOT CHANGE: Breaks if set to wav, and server asks for ogg. It triggers this weird vorbis encoder js bug that spits out tons of aborts in the console.
        encodingWorkerPath: encodingWorkerPath,
        analyser: false
      },
      ultrasound: {
        volume: 0.4
      },
      callback: {
        onAudioInitSuccess: $.noop,
        onComplete: $.noop,
        onDeviceTimeout: $.noop,
        onError: $.noop,
        onNewDeviceMustApprove: $.noop,
        onRecordingStart: $.noop,
        onRecordingTooSilent: $.noop,
        onRecordingEnd: $.noop,
        onRetry: $.noop,
        onSoundProofFailed: $.noop,
        onTimeout: $.noop
      },
      remote: {
        apiPrefix: '/usr/v1'
      },
      rollbar: {
        enabled: 'true' == 'true',
        accessToken: 'c953bf5300c340b19e20c118ac54707b',
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          client: {
            javascript: {
              source_map_enabled: 'false' == 'true',
              code_version: '0.2.6',
              // Optionally have Rollbar guess which frames the error was thrown from
              // when the browser does not provide line and column numbers.
              guess_uncaught_frames: true
            }
          },
          environment: 'productionApi'
        }
      },
      socket: {
        probingEnabled: true,
        recordingTimeout: parseInt(''),
        endpoint: '/sock',
        timeout: parseInt(''),
        transports: []
      },
      timeout: 60000,
      timesync: {
        count: 5,
        websocket: true // execute the protocol over the sockjs connection
      }
    };

    // SoundProof error codes (in sync with the webapp server)
    var SOUNDPROOF_WEB_AUDIO_ERROR = -113;
    var SOUNDPROOF_WEB_MIC_ACCESS_DENIED = -114;


    Rollbar.init(defaultConfig.rollbar);
    Rollbar.configure({
      checkIgnore: function (isUncaught, args, payload) {

        if (!isUncaught) {
          return false;
        }

        try {
          var filename = payload.body.trace.frames[0].filename;
          var isNotBlocked = ['soundproof'].some(function (fname) {
            return filename.indexOf(fname) !== -1;
          });

          if (isNotBlocked && SoundProof.checkSupport()) {
            return false;
          }

          log('Ignoring error from ' + filename);

        } catch (e) {
          log("Rollbar checkIgnore error ", e);
        }

        return true;
      }
    });

    var defaultPlainSoundProofFailUserMsg = "Ooops! Something went wrong while logging you in using SoundProof... Please try again or use another method to login.";
    var defaultApproveComboSoundProofFailUserMsg = "SoundProof failed, you can accept this login by reaching to your phone.";


    if (defaultConfig.socket.probingEnabled) {
      new Socket.TransportProber(defaultConfig).probeTransports();
    }


    /**
     * This callback gets the result of SoundProof support check. If the check passed, i.e., SoundProof is supported on this browser and system, then {@linkcode supported}
     * will be true and {@linkcode err} will be undefined. If the check failed, then {@linkcode supported} will be false and {@linkcode err} will describe the why the check failed.
     * @callback soundproof~checkSupportCallback
     * @param {boolean} supported - Indicates whether the check passed or failed.
     * @param {string} err - A string describing the error if the check failed.
     */

    /**
     * This callback gets called once the browser audio recording interface has been initialized successfully.
     * @callback soundproof~onAudioInitSuccess
     */

    /**
     * This callback gets called once SoundProof has been completed. The result will only be visible to the server,
     * who can retrieve it via the /user/auth and /user/auth_status endpoints of the Auth API.
     * @callback soundproof~onComplete
     */

    /**
     * This callback gets called if the user's device cannot be reached within a reasonably short amount of time, so the operation timed out.
     * This may be an indication that the user’s phone does not currently have network connectivity. Hence, a recommended approach is to fallback to mobile TOTP
     * by asking the user to open the mobile app and transfer the currently displayed code.
     * Note that this callback will immediately be followed by more callbacks, in particular {@link soundproof~onTimeout} and {@link soundproof~onComplete} which will signal that SoundProof timed out and completed (unsuccessfully).
     * @callback soundproof~onDeviceTimeout
     * @param {string} displayText - A default message to show to the user. The web application can choose to display another message.
     */

    /**
     * This callback gets called if SoundProof authentication fails due to an error.
     * @callback soundproof~onError
     * @param {(soundproof.BadSampleRateError|soundproof.BrowserMicError|soundproof.GenericSoundProofError|soundproof.MediaError|soundproof.MobileAppMicPermissionError|soundproof.MobileAppMicBusyError|soundproof.NetworkError)} err - An optional error
     * object describing the error that occurred, if any. It may be undefined.
     * @param {string} displayText - A default message to show to the user. The web application can choose to display another message.
     */

    /**
     * This callback gets called if SoundProof failed because the user is logging in for the first time from this browser, and he
     * must manually approve the login (see "new_device_must_approve" param in /user/auth endpoint of the Futurae Auth API).
     * Your application can inform the user to reach for his/her device and accept or reject the login.
     * @callback soundproof~onNewDeviceMustApprove
     * @param {string} displayText - A default message to show to the user. The web application can choose to display another message.
     */

    /**
     * This callback gets called when SoundProof starts recording audio.
     * @callback soundproof~onRecordingStart
     */

    /**
     * This callback gets called when SoundProof finishes recording audio.
     * @callback soundproof~onRecordingEnd
     */

    /**
     * This callback gets called if SoundProof detects that there is not enough audio energy in the environment.
     * A message can be displayed to the user to encourage him to make some arbitrary noise in order to inject some audio energy in the environment.
     * @callback soundproof~onRecordingTooSilent
     * @param {string} displayText - A default message to show to the user. The web application can choose to display another message.
     */

    /**
     * This callback gets called if the audio comparison fails for some reason and SoundProof automatically retries (one or maximum two times).
     * @callback soundproof~onRetry
     * @param {string} displayText - A default message to show to the user. The web application can choose to display another message.
     */

    /**
     * This callback gets called if SoundProof failed while performed together with the approve combo. The process will still
     * continue until the user accepts/rejects the login or the approve factor times-out. Your application can inform the user
     * to reach for his/her device and accept or reject the login. Note that {@link soundproof~onError} will still get called in order
     * provide more information about the error that caused SoundProof to fail. This callback just indicates that the current
     * authentication attempt is not over yet, even if SoundProof failed, as the user can still manually approve the login.
     * @callback soundproof~onSoundProofFailed
     * @param {string} displayText - A default message to show to the user. The web application can choose to display another message.
     */

    /**
     * This callback gets called if SoundProof times out before the protocol has been completed. Your application will
     * have to start the SoundProof authentication once again, or retry with another factor.
     * @callback soundproof~onTimeout
     */

    // * @param {boolean} config.initAudio - TODO
    /**
     * Initialize a SoundProof authentication process.
     * @constructor
     * @memberof soundproof
     * @param {Object} config - An object containing configuration properties and callback functions, which are triggered upon various events during the SoundProof authentication process.
     * @param {string} config.baseURL - Optional. The base URL of the Futurae server backend to which the SoundProof object should connect to. Default value is "https://api.futurae.com".
     * @param {string} config.audio.deviceId - Optional. A string specifying the device Id which is required to be used as a microphone input for SoundProof. See [MediaDevices.enumerateDevices()]{@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices} for information on how to enumerate devices and select a specific device. Omitting this param makes SoundProof try to heuristically find and use the builtin microphone, if possible, or fallback to the default system input. Specify "default" to force use of default system input.
     * @param {string} config.audio.encodingWorkerPath - Optional. By default it is assumed that the JavaScript audio encoding web workers are served from the same path through which soundproof.min.js is served. If this is not the case, you can specify the path here (example: "/assets/js/").
     * @param {soundproof~onAudioInitSuccess} config.callback.onAudioInitSuccess - Optional. Called once the browser audio recording interface has been initialized successfully.
     * @param {soundproof~onComplete} config.callback.onComplete - Optional. Called once SoundProof authentication has been completed.
     * @param {soundproof~onDeviceTimeout} config.callback.onDeviceTimeout - Optional. Called if the user's device cannot be reached within a reasonably short amount of time. This means that the SoundProof authentication attempt timed out so more callbacks are going to be immediately invoked ({@link soundproof~onTimeout} and {@link soundproof~onComplete}).
     * @param {soundproof~onError} config.callback.onError - Optional. Called if SoundProof authentication fails due to an error.
     * @param {soundproof~onNewDeviceMustApprove} config.callback.onNewDeviceMustApprove - Optional. Called if SoundProof failed because the user is logging in for the first time from this browser, and he must manually approve the login (see "new_device_must_approve" param in /user/auth endpoint of the Futurae Auth API).
     * @param {soundproof~onRecordingStart} config.callback.onRecordingStart - Optional. Called when SoundProof starts recording audio.
     * @param {soundproof~onRecordingEnd} config.callback.onRecordingEnd - Optional. Called when SoundProof finishes recording audio.
     * @param {soundproof~onRecordingTooSilent} config.callback.onRecordingTooSilent - Optional. Called if SoundProof detects that there is not enough audio energy in the environment.
     * @param {soundproof~onRetry} config.callback.onRetry - Optional. Called if the audio comparison fails for some reason and SoundProof automatically retries (one or maximum two times).
     * @param {soundproof~onSoundProofFailed} config.callback.onSoundProofFailed - Optional. Called if SoundProof fails while performed with the approve combo enabled.
     * @param {soundproof~onTimeout} config.callback.onTimeout - Optional. Called if SoundProof times out before the protocol has been completed.
     * @param {array} config.socket.transports - Optional. SoundProof makes use of SockJS in order to establish a WebSocket connection with the Futurae server. SockJS supports a number of fallback transports in case a WebSocket is not feasible. This option allows you to supply a list transports that may be used by SockJS. By default all available transports will be used. For example, by specifying ['xhr-streaming', 'xhr-polling'] you allow only XHR-based transports. For more information see also <a href="https://github.com/sockjs/sockjs-client">https://github.com/sockjs/sockjs-client</a>.
     *
     * @property {string} version - The version of the SoundProof JS library.
     */
    function SoundProof(config) {
      var self = this;
      /* In case you're wondering why we're renaming `this` to `self`, just think
       * of all the $.proxy (or similar) calls we can safe to get the context right
       * for every callback.
       */

      self.config = config = $.extend(true, {}, defaultConfig, config);

      log("baseURL: " + self.config.baseURL);

      self.remote = new Remote(config);
      self.audio = new Audio(config);
      self.socket = new Socket(config);

      self.storage = null;
      try {
        self.storage = new Storage("localStorage");
      } catch (e) {
        console.log(e); // this should never really happen in supported browsers
      }
      self.timeSync = new TimeSync(config, self.remote, self.socket);
      self.encryption = new Encryption(config);

      self.soundproofAttempt = null;
      self.audioInitResult = null;
      self.sessionToken = null;
      self.sessionShortUserId = null;
      self.callbackCalled = {}; // keep track of callbacks and do not call twice

      self.soundProofTokenKeyPrefix = "__futurae_sp_token_";


      self.isApproveCombo = false;
      self.soundProofFailedCalled = false;
      self.completed = false;

      self.defaultSoundProofFailedUserMsg = "";


      /**
       * Start the SoundProof authentication process.
       * @method start
       * @memberof soundproof.SoundProof
       * @instance
       * @param {string} sessionToken - The session token, which is retrieved by calling the /user/auth_prep endpoint of the Auth API in the backend.
       */
      self.start = function (sessionToken) {
        var msg;
        if (!sessionToken || typeof sessionToken !== 'string') {
          msg = "You need to supply a session token";
          log(msg);
          if (self.config.callback.onError) {
            self.config.callback.onError(new GenericSoundProofError(msg),
              defaultPlainSoundProofFailUserMsg);
          }
          return $.Deferred().reject().promise();
        }

        self.sessionToken = sessionToken;
        self.remote.setSessionToken(self.sessionToken);

        self.sessionShortUserId = self.parseShortUserId(self.sessionToken);
        self.isApproveCombo = self.parseApproveComboFlag(self.sessionToken);

        self.defaultSoundProofFailedUserMsg = (self.isApproveCombo) ?
          defaultApproveComboSoundProofFailUserMsg : defaultPlainSoundProofFailUserMsg;


        if (self.soundproofAttempt !== null && self.soundproofAttempt.state() === "pending") {
          msg = "A SoundProof attempt is already in progress.";
          log(msg);
          if (self.config.callback.onError) {
            self.config.callback.onError(new GenericSoundProofError(msg),
              self.defaultSoundProofFailedUserMsg);
          }
          if (self.isApproveCombo && self.config.callback.onSoundProofFailed) {
            self.config.callback.onSoundProofFailed(
              self.defaultSoundProofFailedUserMsg);
          }
          return $.Deferred().reject().promise();
        }

        if (!self.checkSupport()) {
          msg = "SoundProof is not supported in this browser.";
          if (self.config.callback.onError) {
            self.config.callback.onError(new GenericSoundProofError(msg),
              self.defaultSoundProofFailedUserMsg);
          }
          if (self.isApproveCombo && self.config.callback.onSoundProofFailed) {
            self.config.callback.onSoundProofFailed(
              self.defaultSoundProofFailedUserMsg);
          }
          return $.Deferred().reject().promise();
        }


        log("Attempting to authenticate using SoundProof, session token " + self.sessionToken);

        self.callbackCalled = {};

        self.soundproofAttempt = $.Deferred()
                                  .done(self.success)
                                  .fail(self.fail)
                                  .progress(self.progress);

        log("Starting process");

        if (self.audioInitResult === null) {
          self.initAudio();
        }

        self.audioInitResult
            .done(self.sendSocketAuthentication)
            .fail(function (error) {
              if (error && error.name === "NotAllowedError") {
                self.sendSocketAuthenticationWithError(SOUNDPROOF_WEB_MIC_ACCESS_DENIED);
              } else {
                self.sendSocketAuthenticationWithError(SOUNDPROOF_WEB_AUDIO_ERROR);
              }
            });

        return self.soundproofAttempt.promise();
      };

      self.sendSocketAuthentication = function () {
        self.sendSocketAuthenticationWithError();
      };

      self.sendSocketAuthenticationWithError = function (error) {
        log("Sending socket authentication ", self.sessionToken);

        self.socket.sendAuthentication(self.sessionToken,
          self.getSoundProofToken(self.sessionShortUserId),
          self.audio.targetSampleRate(), self.audio.recordingSampleRate(),
          SoundProof.version, self.browserInfo(), error)
            .done(self.startRecording)
            .fail(self.socketError);
        self.socket.waitFor("not_authenticated")
            .done(self.socketNotAuthenticated)
            .fail(self.socketError);
        self.socket.waitFor("device_unreachable")
            .done(self.deviceTimeout)
            .fail(self.socketError);
        self.socket.waitFor("soundproof_failed")
            .done(self.soundProofFailed)
            .fail(self.socketError);
        self.socket.waitFor("soundproof_timeout")
            .done(self.timeout)
            .fail(self.socketError);
        // Wait for final session result in case of an early failure
        self.socket.waitFor("result")
            .done(self.sessionResult)
            .fail(self.timeout);
      };

      self.socketError = function (err) {
        if (self.completed) {
          return;
        }
        var msg = "Sockjs error";
        log(msg, err);
        self.audio.forceStopRecording(); // recording may not have started yet, but it ensures audio contexts and streams are closed
        self.remote.sendSockConnError(err);
        self.soundproofAttempt
            .notify("onError", new NetworkError("Connection to server was interrupted"),
              self.defaultSoundProofFailedUserMsg)
            .reject("Server connection error");
      };

      self.socketNotAuthenticated = function () {
        var msg = "Web socket authentication error";
        log(msg);
        self.audio.forceStopRecording(); // recording hans't started yet, but it ensures audio contexts and streams are closed
        self.soundproofAttempt
            .notify("onError", new GenericSoundProofError(msg),
              self.defaultSoundProofFailedUserMsg)
            .reject(msg);
      };

      self.deviceTimeout = function () {
        log("Could not reach device");
        self.audio.forceStopRecording(); // recording hans't started yet, but it ensures audio contexts and streams are closed
        self.soundproofAttempt.notify(
          "onDeviceTimeout", "Unable contact your mobile device. Authentication timed out");
      };

      self.soundProofFailed = function (data) {
        log("SoundProof failed while in SP+Approve combo");
        if (!self.isApproveCombo) {
          // this should never happen
          log(
            "Received soundproof_failed from server but locally our approve combo flag is false. This should never happen!!");
          self.isApproveCombo = true;
          self.defaultSoundProofFailedUserMsg = defaultApproveComboSoundProofFailUserMsg;
        }

        self.audio.forceStopRecording();

        self.soundProofFailedCalled = true;

        if (data.extras === "new_device_must_approve") {
          self.soundproofAttempt.notify("onNewDeviceMustApprove",
            "You are logging in for the first time from this browser. You must reach to your phone and approve the login.");
          return;
        }

        self.soundproofAttempt.notify("onError",
          self.getErrorBasedOnErrorName(data.extras),
          self.defaultSoundProofFailedUserMsg)
            .notify("onSoundProofFailed", self.defaultSoundProofFailedUserMsg);
      };

      self.initAudio = function () {
        self.audioInitResult = $.Deferred();
        self.audio.init()
            .done(self.audioInitSuccess)
            .fail(self.audioInitFail);
      };

      self.audioInitFail = function (mediaError, msg) {
        log("Audio initialization failed");
        var err = new MediaError(mediaError,
          "Unable to get access to the microphone.");
        self.soundproofAttempt.notify("onError", err,
          self.defaultSoundProofFailedUserMsg);
        // Do no reject soundproofAttempt promise here.
        // (allows to receive updates via server after socket connection)
        self.socket.connectSocket(self.sessionToken)
            .done(function () {
              self.audioInitResult.reject(mediaError);
            }).fail(function (err) {
          self.audioInitResult.reject();
          self.remote.sendSockConnError(err);
        });
      };

      self.audioInitSuccess = function () {
        log("Audio initialized");
        self.soundproofAttempt.notify("onAudioInitSuccess");
        self.socket.connectSocket(self.sessionToken)
            .done(self.audioInitResult.resolve)
            .fail(function (err) {
              self.remote.sendSockConnError(err);
              self.soundproofAttempt.notify("onError", new NetworkError("Unable to connect to server"),
                self.defaultSoundProofFailedUserMsg)
                  .reject("Unable to connect to server");
            });
      };

      self.startRecording = function (data) {
        self.encryption.setPublicKey(data.device_public_key);
        self.audioSampleId = data.sample_id;

        // non default encoding mandated by the server
        if (data.sample_format && data.sample_format !== self.config.audio.format) {
          self.config.audio.format = data.sample_format;
          self.audio.setEncoding(self.config.audio.format);
          log("Switched encoding to: " + self.config.audio.format);
        }

        if (data.ultrasound_params && data.ultrasound_params[0] === 1) {
          self.audio.enableUltrasound(data.ultrasound_pattern,
            data.ultrasound_params);
        } else {
          self.audio.disableUltrasound();
        }

        log("Starting to record");
        self.soundproofAttempt.notify("onRecordingStart");
        self.timeSync.run();
        self.audio.startRecording()
            .done(self.doneRecording)
            .fail(self.recordingFailed)
            .progress(self.recordingProgress);
      };

      self.recordingProgress = function (message, start, end) {
        if (message === "end") {
          self.recordingStart = self.timeSync.syncDate(start);
          self.recordingEnd = self.timeSync.syncDate(end);

        } else if (message === "power") {
          self.soundproofAttempt.notify("onRecordingTooSilent", "Recording is too silent.");

        } else if (message === "zero_power") {
          self.soundproofAttempt.notify("onRecordingTooSilent", "Your mic is probably muted.");
        }
      };

      self.recordingFailed = function (error, message) {
        self.socket.sendError(message);
      };

      self.doneRecording = function (data) {
        log("Recording complete");
        self.soundproofAttempt.notify("onRecordingEnd");
        if (self.completed) {
          // if already completed (i.e. ultrasound was successful) don't bother with encrypting
          // and sending the recording (avoid some request error messages in the browser console)
          return;
        }

        encryptionObject = self.encryption.encrypt(data);
        // self.audioSessionResult.done(self.sendAudioData.bind(self, encryptionObject));
        self.sendAudioData(encryptionObject);
      };

      self.sendAudioData = function (encryptionObject, audioSessionData) {
        log("Sending encrypted audio data to the server");

        // Clear all socket wait handlers before defining new ones to get
        // rid of duplicates. Used when soundproof retrying.
        self.socket.clearWaitForMessages();

        // Wait for another rec message in case of soundproof retrying
        // also device_unreachable, soundproof_failed, soundproof_timeout
        self.socket.waitFor("rec")
            .done(self.startRecording)
            .fail(self.socketError);
        self.socket.waitFor("device_unreachable")
            .done(self.deviceTimeout)
            .fail(self.socketError);
        self.socket.waitFor("soundproof_failed")
            .done(self.soundProofFailed)
            .fail(self.socketError);
        self.socket.waitFor("soundproof_timeout")
            .done(self.timeout)
            .fail(self.socketError);

        // Wait for final session result
        self.socket.waitFor("result")
            .done(self.sessionResult)
            .fail(self.timeout);

        self.remote.sendAudioData(self.audioSampleId, self.recordingStart,
          self.recordingEnd,
          encryptionObject.data, encryptionObject.key, encryptionObject.iv,
          self.audio.recorder.options[self.config.audio.format].mimeType)
            .fail(self.asyncOperationFailed);
      };

      self.asyncOperationFailed = function (errorMsg) {
        log("Async operation failed", errorMsg);
        self.soundproofAttempt.notify("onError",
          new GenericSoundProofError(errorMsg),
          self.defaultSoundProofFailedUserMsg)
            .reject("Async operation failed");
      };

      self.sessionResult = function (message) {
        log("Received result from server");

        if (message.session_token !== self.sessionToken) {
          log("Result does not refer to the current session");
          return;
        }

        if (message.result) {
          if (message.soundproof_token !== null && message.soundproof_token !== "") {
            // mark this browser as "seen" so that we can always use SoundProof from now on
            self.storeSoundProofToken(self.sessionShortUserId, message.soundproof_token);
          }
          self.soundproofAttempt.resolve(message.reason);
          return;
        }

        self.soundproofAttempt.notify("onError",
          self.getErrorBasedOnErrorName(message.error),
          self.defaultSoundProofFailedUserMsg)
            .reject(message.reason);
      };

      self.timeout = function () {
        log("A request to the SoundProof server timed out");

        self.soundproofAttempt.notify("onTimeout").reject("Timeout");
      };

      self.fail = function (reason) {
        log("SoundProof authentication failed:", reason);
        self.audio.forceStopRecording();
        self.audio.forceStopUltrasound();
        self.socket.clearWaitForMessages();

        if (self.isApproveCombo && !self.soundProofFailedCalled) {
          self.config.callback.onSoundProofFailed(self.defaultSoundProofFailedUserMsg);
        }

        self.config.callback.onComplete();
        self.setCompleted();
      };

      self.success = function () {
        log("SoundProof authentication succeeded");
        self.audio.forceStopRecording();
        self.audio.forceStopUltrasound();
        self.socket.clearWaitForMessages();
        self.config.callback.onComplete();
        self.setCompleted();
        // self.soundproofAttempt = null;
      };

      self.progress = function (state) {
        // Wanted to use rest parameters (state, ...ags), but r.js complained
        var args = Array.prototype.slice.call(arguments, self.progress.length);
        if (self.callbackCalled[state]) {
          log("Progress (already called, skipping): " + state, args);
          return;
        }
        log("Progress: " + state, args);
        self.config.callback[state].apply(self, args);
        self.callbackCalled[state] = true;
      };

      self.setCompleted = function () {
        self.completed = true;
        self.audio.finished = true;
        self.socket.close();
      };

      self.parseShortUserId = function (sessionToken) {
        return sessionToken.substring(0, sessionToken.indexOf("-"));
      };

      self.parseApproveComboFlag = function (sessionToken) {
        if (sessionToken[sessionToken.indexOf("-") + 1] === "0") {
          return false;
        } else {
          return true;
        }
      };

      self.getSoundProofToken = function (id) {
        var token = null;
        if (self.storage) {
          token = self.storage.getItem(self.soundProofTokenKeyPrefix + id);
        }
        return token;
      };

      self.storeSoundProofToken = function (id, token) {
        if (self.storage) {
          self.storage.setItem(self.soundProofTokenKeyPrefix + id, token);
          return true;
        }
        return false;
      };

      self.browserInfo = function () {
        return Adapter.browserDetails.browser
               + " " + Adapter.browserDetails.version
               + " " + navigator.platform;
      };

      self.getErrorBasedOnErrorName = function (errorName) {
        var error;
        switch (errorName) {
          case "BadSampleRateError":
            error = new BadSampleRateError();
            break;
          case "BrowserMicError":
            error = new BrowserMicError();
            break;
          case "MobileAppMicPermissionError":
            error = new MobileAppMicPermissionError();
            break;
          case "MobileAppMicBusyError":
            error = new MobileAppMicBusyError();
            break;
          case "NetworkError":
            error = new NetworkError();
            break;
          case "GenericSoundProofError":
            error = new GenericSoundProofError();
            break;
          default:
            error = undefined;
        }
        return error;
      };
    }

    /**
     * Check browser support. Returns the result in a callback.
     * @method checkSupport
     * @memberof soundproof.SoundProof
     * @param {soundproof~checkSupportCallback} cb - A callback with the result of the SoundProof support check.
     */
    SoundProof.checkSupport = function (cb) {
      var result = false;
      var err = "Not a supported browser.";

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

        result = true;
        err = undefined;
      }

      if (typeof(cb) === 'function') {
        cb(result, err);
      }
      return result; // we currently return the result directly as well (undocumented, as it might change in the future depending on the types of checks we do)
      // ATTENTION: our own SoundProof.start() function uses the return value currently, so be careful if you plan to remove it!!
    };

    // also expose it as an object instance function
    SoundProof.prototype.checkSupport = SoundProof.checkSupport;


    var Exported = {};

    Exported.SoundProof = SoundProof;

    Exported.version = '0.2.6';
    SoundProof.version = Exported.version;
    log("SoundProofJS version: " + SoundProof.version);
    console.log("SoundProofJS version: " + SoundProof.version);


    /**
     * This error indicates that the recording sample rate at which the browser is recording is not supported by SoundProof.
     * This sample rate is system determined and cannot be controlled or configured by the JavaScript code. The sample rates that are guaranteed
     * to be compatible are 44100 Hz and 48000 Hz.
     * @constructor
     * @augments external:Error
     * @memberof soundproof
     * @param {string} message - Optional. Human-readable description of the error.
     *
     * @property {string} message - Error message.
     * @property {string} name - Error name.
     */
    function BadSampleRateError(message) {
      this.name = "BadSampleRateError";
      this.message = (message || "");
    }

    BadSampleRateError.prototype = Error.prototype;
    Exported.BadSampleRateError = BadSampleRateError;


    /**
     * This error indicates that even if access to the microphone was successful
     * (i.e., [MediaDevices.getUserMedia()]{@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia}
     * was called successfully) the browser microphone input was zero, probably because the user has muted the microphone,
     * or due to some other browser-access-to-microphone related problem. You may prompt the user to unmute the microphone.
     * Moreover, sometimes microphone related problems can be resolved by simply restarting the browser, thus you may as well
     * prompt the user to restart his browser and try again.
     * @constructor
     * @augments external:Error
     * @memberof soundproof
     * @param {string} message - Optional. Human-readable description of the error.
     *
     * @property {string} message - Error message.
     * @property {string} name - Error name.
     */
    function BrowserMicError(message) {
      this.name = "BrowserMicError";
      this.message = (message || "");
    }

    BrowserMicError.prototype = Error.prototype;
    Exported.BrowserMicError = BrowserMicError;

    /**
     * This error indicates represents a generic SoundProof error. Check the message property in order to
     * potentially get more information about the nature of the error.
     * @constructor
     * @augments external:Error
     * @memberof soundproof
     * @param {string} message - Optional. Human-readable description of the error.
     *
     * @property {string} message - Error message.
     * @property {string} name - Error name.
     */
    function GenericSoundProofError(message) {
      this.name = "GenericSoundProofError";
      this.message = (message || "");
    }

    GenericSoundProofError.prototype = Error.prototype;
    Exported.GenericSoundProofError = GenericSoundProofError;


    /**
     * This error encapsulates an error returned by [MediaDevices.getUserMedia()]{@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia}, which is
     * the function used in order to get access to the microphone. The `getUserMediaError` property contains the error object as returned by [getUserMedia()]{@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia}.
     * Please refer to the respective [documentation]{@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia} in order to find information about
     * the returned errors and how to react respectively. For example, if the user does not grant access to the microphone, a
     * [NotAllowedError]{@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#Exceptions} will be returned and you can choose to
     * explain to the user why he should enable access to the microphone and instructions on how to do it. Alternatively, you might choose to immediately
     * fallback to another secondary authentication method.
     * @constructor
     * @augments external:Error
     * @memberof soundproof
     * @param {external:DOMException} getUserMediaError - The error as returned by [MediaDevices.getUserMedia()]{@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia}.
     * @param {string} message - Optional. Human-readable description of the error.
     *
     * @property {external:DOMException} getUserMediaError - The error as returned by [MediaDevices.getUserMedia()]{@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia}.
     * @property {string} message - Error message.
     * @property {string} name - Error name.
     */
    function MediaError(getUserMediaError, message) {
      this.name = "MediaError";
      this.message = (message || "");

      this.getUserMediaError = getUserMediaError;
    }

    MediaError.prototype = Error.prototype;
    Exported.MediaError = MediaError;

    /**
     * This error indicates that the user has not granted the Futurae mobile app access to the microphone, and thus SoundProof cannot be executed.
     * You should prompt the user to grant the Futurae mobile app permission to record on the user's mobile device.
     * @constructor
     * @augments external:Error
     * @memberof soundproof
     * @param {string} message - Optional. Human-readable description of the error.
     *
     * @property {string} message - Error message.
     * @property {string} name - Error name.
     */
    function MobileAppMicPermissionError(message) {
      this.name = "MobileAppMicPermissionError";
      this.message = (message || "");
    }

    MobileAppMicPermissionError.prototype = Error.prototype;
    Exported.MobileAppMicPermissionError = MobileAppMicPermissionError;

    /**
     * This error indicates that the Futurae mobile app is not able to record audio, because the microphone on the mobile device is busy or unavailable
     * (probably used by another application), and thus SoundProof cannot be executed.
     * If approve combo is used, you should prompt the user to manually approve the login. Otherwise you should fallback to another secondary authentication method.
     * @constructor
     * @augments external:Error
     * @memberof soundproof
     * @param {string} message - Optional. Human-readable description of the error.
     *
     * @property {string} message - Error message.
     * @property {string} name - Error name.
     */
    function MobileAppMicBusyError(message) {
      this.name = "MobileAppMicBusyError";
      this.message = (message || "");
    }

    MobileAppMicBusyError.prototype = Error.prototype;
    Exported.MobileAppMicBusyError = MobileAppMicBusyError;


    /**
     * This error indicates that SoundProofJS was unable to connect to the Futurae server (or connection was interrupted), and thus SoundProof cannot be executed successfully.
     * This is often likely due to firewall policies or other restrictive measures that block cross-origin communication.
     * If approve combo is used, you should prompt the user to manually approve the login. Otherwise you should fallback to another secondary authentication method.
     * Note that, because the connection to the server failed you will not be able to receive further updates about the authentication attempt on the client-side.
     * @constructor
     * @augments external:Error
     * @memberof soundproof
     * @param {string} message - Optional. Human-readable description of the error.
     *
     * @property {string} message - Error message.
     * @property {string} name - Error name.
     */
    function NetworkError(message) {
      this.name = "NetworkError";
      this.message = (message || "");
    }

    NetworkError.prototype = Error.prototype;
    Exported.NetworkError = NetworkError;


    log("Ready");

    return Exported;
  });

