//
// ultrasound-modulation.js
//
// Created by Michail Resvanis on 10/08/2017.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential.
//
// Copyright (C) 2017 Futurae Technologies AG - All rights reserved.
// For any inquiry, contact: legal@futurae.com
//


define('ultrasound-modulation',['jquery', 'debug'], function ($, Debug) {
  var log = Debug("soundproof:ultrasound");
  var context = null;

  function Ultrasound(config) {
    var self = this;

    var AudioContext = window.AudioContext // Default
                       || window.webkitAudioContext // Safari and old versions of Chrome
                       || false;

    self.config = config;

    // some default values, will be overwritten by the server supplied param values
    self.startFrequency = 19000;
    self.onsetFrequency = 19900;
    self.totalDuration = config.audio.duration;
    self.repeat = 0;
    self.itDuration = 1000;
    self.iterationInterval = 0;
    self.bitDepth = 5;
    self.minGain = config.ultrasound.volume;
    self.maxGain = config.ultrasound.volume;
    self.simultanious_frequencies = 1;
    self.bucketSize = 25;

    self.currGain = 0;
    self.redundancy = 1;

    if (context) {
      context.close().then(function () {
        log('Old context closed');
      }).catch(function (e) {
        log(e);
      });
    }
    context = new AudioContext();
    self.context = context;

    self.tones = null;
    self.mixer = null;
    self.filter = null;

    self.stopped = false;

    self.send = function (data, params) {
      // try to resume audio context in case it was created prematurely
      // (before any user interaction on the page)
      if (self.context.resume) {
        self.context.resume().then(function () {
          log("Emission AudioContext resumed successfully");
          self.emit(data, params);

        }).catch(function (reason) {
          log("Failed to resume emission AudioContext", reason);
        });
        return;
      }

      log("No option to resume AudioContext");
      self.emit(data, params);
    };

    self.emit = function (data, params) {
      self.configure(params);

      if (self.context.sampleRate < 44100) {
        log("AudioContext sample rate too low. Skipping emission");
        self.stopped = true;
        return;
      }

      self.stopped = false;

      log("Sending", data);
      log("Total duration", self.totalDuration);

      self.reset();
      self.mixer = self.context.createGain();
      self.mixer.gain.value = 0;

      self.filter = self.context.createBiquadFilter();

      self.frequencies = self.getFrequencies(data);
      for (var i = 0; i < self.frequencies.length; i++) {
        log("Frequencies (inc. onset)", self.frequencies[i]);
      }

      self.tone = null;

      var tone = self.createTone(self.onsetFrequency);
      self.filter.type = "bandpass";
      self.filter.frequency.value = self.onsetFrequency;
      self.filter.Q.value = 43;
      tone.connect(self.filter);
      self.filter.connect(self.mixer);

      self.tone = tone;

      self.mixer.connect(self.context.destination);
      self.iterationInterval = self.computeIterationInterval();
      self.interval = (self.totalDuration - self.iterationInterval) / self.repeat / (self.frequencies[0].length); // symbol duration
      self.frequency_index = 0;
      self.iteration = 1;

      self.currGain = self.adjustGain();

      log("iterationInterval", self.iterationInterval);
      log("interval", self.interval);
      log("initial curGrain", self.currGain);

      self.nextFrequency();
    };

    self.nextFrequency = function () {
      try {
        self.tone.stop();
        self.tone.disconnect();
      } catch (err) {
        // Catch error that Safari throws if stop() is called more than once
      }
      if (self.stopped) {
        return;
      }
      if (self.frequency_index >= self.frequencies[0].length) {
        if (self.iteration < self.repeat) {
          if (self.iterationInterval > 0) {
            window.setTimeout(self.nextFrequency,
              self.iterationInterval);
            self.iterationInterval = 0;
            return;
          }
          self.iteration++;
          self.iterationInterval = self.computeIterationInterval();
          self.currGain = self.adjustGain();
          log("new curGrain", self.currGain);
          self.frequency_index = 0;

        } else {
          log("Finished ultrasound emission");
          self.stop();
          return;
        }
      }

      var i = self.iteration - 1;
      self.mixer.gain.value = self.currGain;
      log("currGain", self.currGain);

      if (self.frequencies[i].length > self.frequency_index) {

        var freq = self.frequencies[i][self.frequency_index];
        var tone = self.createTone(freq);
        log("Sending frequency", freq);

        self.filter.frequency.value = freq;

        self.tone = tone;
        tone.connect(self.filter);
      }

      self.frequency_index++;
      window.setTimeout(self.nextFrequency, self.interval);
    };


    self.hamming_interval = 10;
    self.hamming_t = 0;
    self.hamming_run = 0;

    self.initHammingWindow = function () {
      self.hamming_N = Math.floor(
        (self.interval / self.hamming_interval)) - 1;
      // log("hamming N", self.hamming_N, self.hamming_t);
      self.hamming_t = 0;
      self.hammingWindow(++self.hamming_run);
    };

    self.hammingWindow = function (run) {
      if (self.hamming_run !== run) { // stop delayed runs
        return;
      }
      self.mixer.gain.value = self.currGain * (0.54 - 0.46 * Math.cos(
        2 * Math.PI * self.hamming_t / self.hamming_N));
      // log("gain value", self.mixer.gain.value, self.hamming_N, self.hamming_t);
      self.hamming_t++;
      if (self.hamming_t <= self.hamming_N) {
        // if (self.hamming_t <= self.hamming_N/2) {
        window.setTimeout(self.hammingWindow, self.hamming_interval,
          run);
      } else {
        // self.mixer.gain.value = 0;
        // self.mixer.gain.value = self.minGain;
        self.hamming_t = 0;
      }
    };

    self.computeIterationInterval = function () {
      if (self.repeat <= 1) {
        return 0;
      }
      return (self.totalDuration - (self.repeat * self.itDuration)) / (self.repeat - 1);
    };

    self.getFrequencies = function (data) {
      // var redundancy_step = self.bucketSize * 64;

      log("pattern length: ", data.length);
      var frequencies = [];
      for (var j = 0; j < self.repeat; j++) {
        frequencies.push([self.onsetFrequency]);
        for (var i = 0; i < data.length; i++) {
          var v = data[i];
          frequencies[j].push(
            self.startFrequency + /*redundancy_step * j +*/ v * self.bucketSize);
        }
      }

      return frequencies;
    };

    self.stop = function () {
      log("stopping ultra sound emission");
      self.stopped = true;
      if (self.tone) {
        try {
          self.tone.stop();
          self.tone.disconnect();
        } catch (err) {
          // Catch error that Safari throws if stop() is called more than once
        }
      }
      if (self.mixer) self.mixer.gain.value = 0;
      if (self.context) {
        self.context.close().catch(function (e) {
          log(e);
        });
      }
      // self.interpolateVolumeDown();
    };

    self.configure = function (params) {

      self.startFrequency = params[1];
      self.bucketSize = params[2];
      self.bitDepth = params[3];
      self.onsetFrequency = params[4];
      self.totalDuration = params[5];
      self.repeat = params[6];
      self.itDuration = params[7];
      self.minGain = params[8] / 100.0;
      self.maxGain = params[9] / 100.0;

      // log(self.startFrequency);
      // log(self.bucketSize);
      // log(self.bitDepth);
      // log(self.onsetFrequency);
      // log(self.totalDuration);
      // log( self.minGain);
    };

    self.reset = function () {
      if (self.mixer) {
        self.mixer.gain.value = 0;
        self.mixer.disconnect();
        self.mixer = null;
      }

      if (self.tones) {
        for (var i = 0; i < self.tones.length; i++) {
          self.tones[i].stop();
        }
        self.tones = null;
      }
    };

    self.createTone = function (frequency) {
      var tone = self.context.createOscillator();
      tone.type = "sine";
      tone.frequency.value = frequency;
      tone.start();
      return tone;
    };

    self.adjustGain = function () {
      if (self.iteration <= 1) {
        return self.minGain;
      } else if (self.iteration >= self.repeat) {
        return self.maxGain;
      } else {
        var gainStep = (self.maxGain - self.minGain) / (self.repeat - 1);
        return self.currGain + gainStep;
      }
      // self.interpolationStep = self.currGain / (self.interpolationDuration / self.interpolationInterval);
    };

    // self.interpolateVolumeUp = function () {
    //     if (!self.mixer) {
    //         return;
    //     }
    //
    //     self.mixer.gain.value += self.interpolationStep;
    //     //log(self.mixer.gain.value);
    //
    //     if (self.mixer.gain.value >= self.currGain) {
    //         self.mixer.gain.value = self.currGain;
    //         log(self.mixer.gain.value);
    //     } else {
    //         window.setTimeout(self.interpolateVolumeUp,
    //             self.interpolationInterval);
    //     }
    // };
    //
    // self.interpolateVolumeDown = function () {
    //     if (!self.mixer) {
    //         return;
    //     }
    //
    //     self.mixer.gain.value -= self.interpolationStep;
    //     //log(self.mixer.gain.value);
    //
    //     if (self.mixer.gain.value <= 0) {
    //         // self.reset();
    //         self.mixer.gain.value = 0;
    //     } else {
    //         window.setTimeout(self.interpolateVolumeDown,
    //             self.interpolationInterval);
    //     }
    // };
  }

  log("Ready");
  return Ultrasound;
});

