//
// main.js
//
// Created by Michail Resvanis on 10/08/2017.
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential.
//
// Copyright (C) 2017 Futurae Technologies AG - All rights reserved.
// For any inquiry, contact: legal@futurae.com
//


define('main',['../../dist/soundproof'], function (Exported) {
  return {
    BadSampleRateError: Exported.BadSampleRateError,
    BrowserMicError: Exported.BrowserMicError,
    GenericSoundProofError: Exported.GenericSoundProofError,
    MobileAppMicPermissionError: Exported.MobileAppMicPermissionError,
    SoundProof: Exported.SoundProof,
    version: Exported.version
  };
});

